
import { Vue } from 'vue-property-decorator'
import mqtt from 'mqtt'
export class MqttMinxin extends Vue {
  private client: any = ''

  mounted () {
    console.log('ccc')
    this.initMqtt()
  }

  // 初始化链接mqtt
  initMqtt () {
    console.log('开始连接')
    const connection = {
      protocol: 'ws',
      host: 'broker.emqx.io',
      port: '8083',
      endpoint: '/mqtt',
      // endpoint: '/device/S0/data',
      clean: true, // 断开连接清除
      connectTimeout: 3 * 1000, // 链接超时时长
      reconnectPeriod: 4000, // 重连间隔时间
      clientId: 'mqttx_a3728a27',
      username: 'emqx_test',
      password: 'emqx_test'
    }
    const { protocol, host, port, endpoint, ...options } = connection
    const connectUrl = `${protocol}://${host}:${port}${endpoint}`
    this.client = mqtt.connect(connectUrl, options)
    console.log(connectUrl, options)
    if (this.client.on) {
      this.client.on('connect', () => {
        console.log('连接成功')
      })
      this.client.on('reconnect', () => {
        // this.handleOnReConnect
        console.log('连接失败')
      })
      this.client.on('error', (error: string) => {
        console.log('连接错误', error)
      })
      this.client.on('message', (topic: any, message: any) => {
        // this.receiveNews = this.receiveNews.concat(message)
        console.log(`Received message ${message} from topic ${topic}`)
      })
    }
    this.client.subscribe('topic/mqttx', { qos: 0 }, (error: any, res: any) => {
      if (error) {
        console.log('Subscribe to topics error', error)
        return
      }
      console.log('Subscribe to topics res', res)
    })
  }
}

export default MqttMinxin
