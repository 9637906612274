import { render, staticRenderFns } from "./Device.vue?vue&type=template&id=4b74b960&scoped=true&"
import script from "./Device.vue?vue&type=script&lang=ts&"
export * from "./Device.vue?vue&type=script&lang=ts&"
import style0 from "./Device.vue?vue&type=style&index=0&id=4b74b960&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b74b960",
  null
  
)

export default component.exports