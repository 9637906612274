
import { Component, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ElTable } from 'element-ui/types/table'
import { DeviceInfo } from '@/types/ecologyMonitor'
import { MqttMinxin } from '../../mixin/mqtt'
import Deploy from './Deploy.vue'
import { MenuItem } from '@/types/menu'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import LocationImg from '@/assets/img/icon_address.png'

interface Table extends ElTable {
  selection: DeviceInfo[];
}

@Component({
  components: { Deploy }
})
export default class Device extends MqttMinxin {
  @Ref('tables') readonly elTable!: Table
  @Ref('allocateForm') readonly elForm!: ElForm
  private searchInfo = {
    deviceName: '',
    deviceNumber: '',
    projectId: '',
    deviceStatus: '',
    allotStatus: ''
  }

  private typeList = [
    {
      name: '土壤监测',
      showType: false
    },
    {
      name: '智慧虫情',
      showType: false
    },
    {
      name: '水质监测',
      showType: false
    },
    {
      name: '环境监测',
      showType: false
    },
    {
      name: '电磁阀',
      showType: true,
      dataFixed: true
    }
  ]

  private deviceType: number | null = null
  private page = 1
  private size = 10
  private total = 0
  private tableData = []
  private loading = false
  private showAllocateDialog = false
  private allocateLoading = false
  allocateInfo: {deviceNumber: string[]; projectId: string} = {
    deviceNumber: [],
    projectId: ''
  }

  // 土壤配置弹窗
  private finds = []
  private soilDialog = false

  allocateRules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ]
  }

  private tableTR = [
    // 土壤
    {
      label: '监测状态',
      prop: 'runStatus',
      type: 0,
      normal: true,
      minWidth: 80
    },
    {
      label: '温度（℃）',
      prop: 'temperature',
      type: 0,
      normal: false,
      minWidth: 100
    },
    {
      label: '湿度（%）',
      prop: 'humidity',
      type: 0,
      normal: false,
      minWidth: 100
    },
    {
      label: 'pH',
      prop: 'ph',
      type: 0,
      normal: false,
      minWidth: 80
    },
    {
      label: 'EC（mS/cm）',
      prop: 'conductivity',
      type: 0,
      normal: false,
      minWidth: 120
    },
    {
      label: 'ORP（mv）',
      prop: 'redox',
      type: 0,
      normal: false,
      minWidth: 100
    },
    {
      label: '氮（mg/kg）',
      prop: 'nitrogen',
      type: 0,
      normal: false,
      minWidth: 100
    },
    {
      label: '磷（mg/kg）',
      prop: 'phosphorus',
      type: 0,
      normal: false,
      minWidth: 100
    },
    {
      label: '钾（mg/kg）',
      prop: 'potassium',
      type: 0,
      normal: false,
      minWidth: 100
    },
    {
      label: '所属物种',
      prop: 'maintTypeName',
      type: 0,
      normal: true,
      minWidth: 80
    },
    {
      label: '地面深度（cm）',
      prop: 'depth',
      type: 0,
      normal: true,
      minWidth: 120
    },
    // 虫情
    {
      label: '工作状态',
      prop: 'ws',
      type: 1,
      normal: true,
      minWidth: 100
    },
    {
      label: '灯管状态',
      prop: 'lamp',
      type: 1,
      normal: true,
      minWidth: 100
    },
    {
      label: '加热仓温度（℃）',
      prop: 'hrt',
      type: 1,
      normal: true,
      minWidth: 120
    },
    {
      label: '环境温度（℃）',
      prop: 'at',
      type: 1,
      normal: true,
      minWidth: 120
    },
    {
      label: '光照强度（Lux）',
      prop: 'lux',
      type: 1,
      normal: true,
      minWidth: 120
    },
    {
      label: '环境湿度（%）',
      prop: 'ah',
      type: 1,
      normal: true,
      minWidth: 120
    },
    {
      label: '雨控状态',
      prop: 'rps',
      type: 1,
      normal: true,
      minWidth: 100
    },
    {
      label: '光控状态',
      prop: 'lps',
      type: 1,
      normal: true,
      minWidth: 100
    },
    {
      label: '温控状态',
      prop: 'tps',
      type: 1,
      normal: true,
      minWidth: 100
    },
    // 水质
    {
      label: '水位（cm）',
      prop: 'waterLevel',
      type: 2,
      normal: false,
      minWidth: 100
    },
    {
      label: 'pH',
      prop: 'ph',
      type: 2,
      normal: false,
      minWidth: 80
    },
    {
      label: '水温（℃）',
      prop: 'temperature',
      type: 2,
      normal: false,
      minWidth: 100
    },
    {
      label: '电导率（μs/cm）',
      prop: 'conductivity',
      type: 2,
      normal: false,
      minWidth: 120
    },
    {
      label: '浊度（NTU）',
      prop: 'turbidity',
      type: 2,
      normal: false,
      minWidth: 100
    },
    {
      label: '溶解氧（mg/L）',
      prop: 'dissolvedOxygen',
      type: 2,
      normal: false,
      minWidth: 120
    },
    {
      label: '氨氮（mg/L）',
      prop: 'ammoniaNitrogen',
      type: 2,
      normal: false,
      minWidth: 100
    },
    // 环境
    {
      label: '温度（℃）',
      prop: 'temperature',
      type: 3,
      normal: false,
      minWidth: 100
    },
    {
      label: '湿度（%）',
      prop: 'humidity',
      type: 3,
      normal: false,
      minWidth: 100
    },
    {
      label: '降雨量（mm)',
      prop: 'precipitation',
      type: 3,
      normal: false,
      minWidth: 110
    },
    {
      label: '气压(pa)',
      prop: 'pressure',
      type: 3,
      normal: false,
      minWidth: 100
    },
    {
      label: '风向',
      prop: 'direction',
      type: 3,
      normal: false,
      minWidth: 100
    },
    {
      label: '风速(m/s)',
      prop: 'speed',
      type: 3,
      normal: false,
      minWidth: 100
    },
    {
      label: '负氧离子(个/cm³）',
      prop: 'negativeOxyge',
      type: 3,
      normal: false,
      minWidth: 130
    },
    {
      label: '噪音（db)',
      prop: 'noise',
      type: 3,
      normal: false,
      minWidth: 100
    },
    {
      label: 'PM2.5（μg/m³）',
      prop: 'pm25',
      type: 3,
      normal: false,
      minWidth: 120
    },
    {
      label: 'PM10（μg/m³）',
      prop: 'pm10',
      type: 3,
      normal: false,
      minWidth: 120
    },
    {
      label: '二氧化碳（ppm）',
      prop: 'co2',
      type: 3,
      normal: false,
      minWidth: 120
    },
    {
      label: '总辐射（mwh/cm²）',
      prop: 'zfs',
      type: 3,
      normal: false,
      minWidth: 140
    },
    {
      label: '光照度（Lux)',
      prop: 'lux',
      type: 3,
      normal: false,
      minWidth: 120
    }
  ]

  private addressShow = false
  private map: any = null
  private marker: Array<any> = []
  info = {
    longitude: '',
    latitude: '',
    deviceNumber: ''
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  get selection () {
    return this.elTable.selection
  }

  created () {
    const data = sessionStorage.getItem('childNode') || ''
    this.getShowType(JSON.parse(data))
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  // 编辑后重新获取数据
  activated () {
    if (this.deviceType !== -1) {
      this.getData()
    }
  }

  getShowType (data: MenuItem) {
    if (data.childNode && data.childNode.length > 0) {
      data.childNode.forEach(item => {
        this.typeList.forEach((items) => {
          if (item.menuName === items.name) {
            items.showType = true
          }
        })
      })
      this.deviceType = this.typeList.findIndex(item => item.showType)
      if (this.deviceType !== -1) {
        this.getData()
      }
    }
  }

  getData () {
    this.loading = true
    const urlList = [
      this.$apis.ecologyMonitor.selectSoilInfoByPage,
      this.$apis.ecologyMonitor.selectInsectInfoByPage,
      this.$apis.ecologyMonitor.selectWaterQualityInfoByPage,
      this.$apis.ecologyMonitor.selectWeatherInfoByPage,
      this.$apis.irrigation.selectIrrigationValveByPage
    ]
    const data = ['soil', '', 'waterQualityInfo', 'weatherInfo']
    this.$axios.get(urlList[Number(this.deviceType)], {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      if (data[Number(this.deviceType)]) {
        // 土壤 水质 环境
        this.total = res[data[Number(this.deviceType)]].total || 0
        this.tableData = res[data[Number(this.deviceType)]].list || []
      } else {
        // 虫情 电磁阀
        this.total = res.total || 0
        this.tableData = res.list || []
      }
      this.finds = res.finds || []
    }).finally(() => {
      this.loading = false
    })
  }

  changeType (index: number) {
    this.deviceType = index
    this.tableData = []
    this.searchData()
  }

  // 判断展示字段
  getTableTRList () {
    return this.tableTR.filter(item => {
      if (item.normal) {
        return true && item.type === this.deviceType
      } else {
        return this.finds.find((items) => items === item.prop) && item.type === this.deviceType
      }
    })
  }

  // 批量分配
  batchAllocate () {
    if (this.selection.length) {
      const isAllot = this.selection.find((item) => item.allotStatus === '1')
      if (isAllot) {
        this.$confirm('勾选项有设备已分配, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.showAllocateDialog = true
          this.allocateInfo.deviceNumber = this.selection.map(item => item.deviceNumber)
        })
      } else {
        this.showAllocateDialog = true
        this.allocateInfo.deviceNumber = this.selection.map(item => item.deviceNumber)
      }
    } else {
      this.$message.error('请选择要分配的设备')
    }
  }

  // 单个分配
  allocate (row: DeviceInfo) {
    if (row.allotStatus === '1') {
      this.$confirm('该设备已分配, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.showAllocateDialog = true
        this.allocateInfo.deviceNumber = [row.deviceNumber]
        this.allocateInfo.projectId = row.projectId || ''
      })
    } else {
      this.showAllocateDialog = true
      this.allocateInfo.deviceNumber = [row.deviceNumber]
    }
  }

  // 分配数据校验
  allocateDevice () {
    this.elForm.validate(valid => {
      if (valid) {
        this.doAllocateDevice()
      }
    })
  }

  // 分配执行
  doAllocateDevice () {
    this.allocateLoading = true
    const urlList = [
      this.$apis.ecologyMonitor.updateSoilInfo,
      this.$apis.ecologyMonitor.updateInsectInfo,
      this.$apis.ecologyMonitor.updateWaterQualityInfo,
      this.$apis.ecologyMonitor.updateWeatherInfo
    ]
    this.$axios.post(urlList[Number(this.deviceType)], this.allocateInfo).then(() => {
      this.$message.success('分配成功')
      this.elTable.clearSelection()
      this.getData()
    }).finally(() => {
      this.allocateLoading = false
      this.showAllocateDialog = false
      this.allocateInfo.projectId = ''
    })
  }

  // 批量配置
  batchDeploy () {
    if (this.selection.length) {
      if (this.deviceType === 0) {
        this.soilDialog = true
        this.allocateInfo.deviceNumber = this.selection.map(item => item.deviceNumber)
      }
    } else {
      this.$message.error('请选择要配置的设备')
    }
  }

  // 单个配置
  deploy (row: DeviceInfo) {
    this.soilDialog = true
    this.allocateInfo.deviceNumber = [row.deviceNumber]
  }

  // 配置弹窗关闭
  closeDialog () {
    this.soilDialog = false
  }

  toDetail (deviceNumber: string) {
    this.$router.push('/ecologyMonitor/device/deviceDetail?deviceNumber=' + deviceNumber + '&deviceType=' + this.deviceType)
  }

  // 历史数据
  toHistory (deviceNumber: string) {
    const nameList = ['deviceSoilHistory', 'deviceInsectHistory', 'deviceWaterHistory', 'deviceWeatherHistory']
    this.$router.push({ name: nameList[Number(this.deviceType)], params: { deviceNumber: deviceNumber } })
  }

  // 地图定位
  onAddressShow (detail: any) {
    this.addressShow = true
    this.info = detail
    this.$nextTick(() => {
      this.initMap()
    })
  }

  // 初始化地图
  initMap () {
    if (this.map) {
      this.info.longitude && this.map.setCenter([this.info.longitude, this.info.latitude])
      this.addMarker(+this.info.longitude, +this.info.latitude)
    } else {
      AMapLoader.load({
        key: mapKey,
        version: '2.0',
        plugins: [
          'AMap.MouseTool'
        ]
      }).then((AMap) => {
        const center = this.info.longitude ? [this.info.longitude, this.info.latitude] : []
        this.map = new AMap.Map('map', {
          zoom: 15,
          ...center
        })
        this.addMarker(+this.info.longitude, +this.info.latitude)
        this.map.on('click', (e: any) => {
          this.info.longitude = e.lnglat.lng
          this.info.latitude = e.lnglat.lat
          this.addMarker(e.lnglat.lng, e.lnglat.lat)
        })
      })
    }
  }

  addMarker (lng: number, lat: number) {
    this.map.remove(this.marker)
    if (lng && lat) {
      this.marker = []
      const marker: any = new AMap.Marker({
        map: this.map,
        position: new AMap.LngLat(lng, lat),
        offset: new AMap.Pixel(-20, -10),
        icon: LocationImg
      })
      marker.setLabel({
        direction: 'top',
        offset: new AMap.Pixel(0, -5)
      })
      this.marker.push(marker)
      this.map.add(marker)
    }
  }

  handleClose () {
    this.addressShow = false
    this.map && this.map.remove(this.marker)
  }

  submitAddress () {
    if (this.info.longitude) {
      this.$axios.post(this.$apis.irrigation.updateIrrigationValveLocation, {
        ...this.info
      }).then(() => {
        this.$message.success('保存成功')
        this.addressShow = false
      })
    } else {
      this.$message.warning('请先进行电磁阀打点后保存!')
    }
  }
}
