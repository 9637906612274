
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { SoilInfo } from '@/types/ecologyMonitor'
import { integerWithin, validNumberOne, validNumberThree, validNumberFour } from '@/utils/validate'

@Component
export default class Deploy extends Vue {
  @Prop({ default: false }) private soilDialog!: boolean
  @Prop() readonly deviceNumber!: Array<string>;
  @Prop() readonly finds!: Array<string>;
  @Ref('form') readonly form!: ElForm;

  private loading = false
  private projectId = ''
  private soilInfo: SoilInfo = {
    maintType: '',
    maintTypeName: '',
    depth: '',
    drought: '',
    saturated: '',
    frozen: '',
    overheated: '',
    // 氮磷钾ORP
    nitrogenUpperLimit: '',
    nitrogenLowerLimit: '',
    phosphorusUpperLimit: '',
    phosphorusLowerLimit: '',
    potassiumUpperLimit: '',
    potassiumLowerLimit: '',
    orpLowerLimit: '',
    orpUpperLimit: ''
  }

  private species: Array<{id: string; name: string}> = []
  private typeList = []

  private rules = {
    maintType: [{ required: true, message: '请选择养护物种', trigger: 'change' }], // 养护物种编号
    depth: [{ required: true, message: '请输入距离地面深度', trigger: 'blur' }], // 距离地面深度/CM
    drought: [
      { required: true, message: '请输入土壤湿度干旱阈值', trigger: 'blur' },
      { validator: integerWithin, trigger: ['blur', 'change'] }
    ], // 土壤湿度干旱阈值/%
    saturated: [
      { required: true, message: '请输入土壤湿度饱和阈值', trigger: 'blur' },
      { validator: integerWithin, trigger: ['blur', 'change'] }
    ], // 土壤湿度饱和阈值/%
    frozen: [
      { required: true, message: '请输入土壤温度冰冻阈值', trigger: 'blur' },
      { validator: validNumberOne, trigger: ['blur', 'change'] }
    ], // 土壤温度冰冻阈值/℃
    overheated: [
      { required: true, message: '请输入土壤温度过热阈值', trigger: 'blur' },
      { validator: validNumberOne, trigger: ['blur', 'change'] }
    ], // 土壤温度过热阈值/℃
    nitrogenLowerLimit: [
      { required: true, message: '请输入氮含量偏低阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    nitrogenUpperLimit: [
      { required: true, message: '请输入氮含量偏高阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    phosphorusLowerLimit: [
      { required: true, message: '请输入磷含量偏低阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    phosphorusUpperLimit: [
      { required: true, message: '请输入磷含量偏高阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    potassiumLowerLimit: [
      { required: true, message: '请输入钾含量偏低阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    potassiumUpperLimit: [
      { required: true, message: '请输入钾含量偏高阈值', trigger: 'blur' },
      { validator: validNumberThree, trigger: ['blur', 'change'] }
    ],
    orpLowerLimit: [
      { required: true, message: '请输入ORP值偏低阈值', trigger: 'blur' },
      { validator: validNumberFour, trigger: ['blur', 'change'] }
    ],
    orpUpperLimit: [
      { required: true, message: '请输入ORP值偏高阈值', trigger: 'blur' },
      { validator: validNumberFour, trigger: ['blur', 'change'] }
    ]
  }

  created () {
    if (this.deviceNumber.length === 1) {
      this.loadData()
    }
    this.getTypeList()
  }

  loadData () {
    this.$axios.get(this.$apis.ecologyMonitor.selectSoilInfoByDeviceNumber, {
      deviceNumber: this.deviceNumber[0]
    }).then(res => {
      this.soilInfo = res || {}
    })
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'maintType' }).then(res => {
      this.typeList = res.maintType || []
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        const query = {
          deviceNumber: this.deviceNumber,
          soilInfo: this.soilInfo
        }
        if (query.soilInfo.maintTypeName) {
          delete query.soilInfo.maintTypeName
        }
        this.loading = true
        this.$axios.post(this.$apis.ecologyMonitor.updateSoilInfoConf, query).then(() => {
          this.$message({ message: '保存成功', type: 'success' })
          this.$emit('getData')
          this.closeDialog()
        }).finally(() => {
          this.loading = false
        })
      }
    })
  }

  closeDialog () {
    this.$emit('closeDialog')
  }
}
